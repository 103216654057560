import './Accueil.css'

import Header from '../Header/Header';
import Images from '../Images/Images';
//import {useEffect, useState} from 'react'


export default function Accueil() {

  /*

const [windowSize,setWidowSize]=useState({
 width: window.innerWidth,
 height: window.innerHeight,
})
const handleResize=()=>{
 setWidowSize({
   width: window.innerWidth,
   height: window.innerHeight,
 })
}

const [d,setD]=useState("M0 0H390V519.648L0 842V0Z")

useEffect(()=>{
 window.addEventListener('resize', handleResize)

 setD(`M0 0H${windowSize.width}V${windowSize.height}L0 ${windowSize.height}V0Z`);
 
 return () => {
   window.removeEventListener('resize', handleResize);
 };
},[windowSize.width, windowSize.height])


*/




  return (
    <div className="accueil" >


      {
        /*
              <svg xmlns="http://www.w3.org/2000/svg" className='svg-bg' width="100%" height="20%" viewBox="0 0 390 842" fill="none">
              <path d="M0 0H390V519.648L0 842V0Z" fill="#0F9EFA"/>
          </svg> 

        */
      }

      <div className="element-accueil">
        <Header />

        <div className='content'>

          <div className='content-element-head'>

            <h3 className='h3-titre-description'>
              Votre santé, livrée pile à l'heure
            </h3>

            <p className='mini-description'>
              Confiez-nous votre bien-être, on s'occupe de la livraison.
            </p>

            <div className="btn-style-spec" >
              Obtenir un devis

            </div>
          </div>

          <Images />

        </div>




      </div>




    </div>
  )
}