
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


// import required modules
import {  Pagination } from 'swiper/modules';


import './Entreprises.css'


//import images
import jean_couteau from "../../assets/jean-couteu.svg"
import pharmaprix from "../../assets/pharmaprix.svg"
import uniprix from "../../assets/uniprix.svg"

export default function Entreprises() {



    return (
        <div className='wrapperDiv'>
            <Swiper
                slidesPerView={2}
                spaceBetween={30}
                FreeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[ Pagination]}
                className="mySwiper" centeredSlides={true}
            >
                <SwiperSlide>
                    <img className='img-slider' src={jean_couteau} alt="jean couteau" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='img-slider' src={uniprix} alt="uniprix" />
                </SwiperSlide>
                
                <SwiperSlide>
                    <img className='img-slider' src={pharmaprix} alt="pharmaprix" />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}