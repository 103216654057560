
import { useState } from 'react'
import logo from '../../assets/PNG-11.png'
import "./Header.css"



export default function Header(){

    const [show,setShow]=useState('hideElementNav')

    const onShowNavBar=(e)=>{
       
        if(show==="hideElementNav"){
            setShow('showElementNav')
        }else{
            setShow('hideElementNav')
        }
    }

    return (
        <header className='header'>
            
            <img src={logo} className='logo' alt='logo du site web' />

           <div className='menu'>
                <div className="logo-menu" onClick={onShowNavBar}>
                    <span className='ligne1 ligne'></span>
                    <span className='ligne2 ligne'></span>
                </div>


                <div className={show}>
                    <a href="#service" className='localLink service '>Service</a>
                    <a href="#nouscontacter" className='localLink'> Nous contacter</a>
                    <button className='obtenirDevise ' >  obtenir un devis</button>
                </div>
           </div>
            
        </header>
    )
}