
import './App.css';
import Accueil from './Components/Accueil/Accueil';
import Entreprises from './Components/Entreprises/Entreprises';
import Footer from './Components/Footer/Footer';
import Services from './Components/Services/Services';
import Suivie from './Components/Suivie/Suivie';
import './base.css'


function App() {

 
  return (
    <div className="App">

        <Accueil/>
        <Suivie />
        <Entreprises/>
        <Services />
        <Footer />

    </div>
  );
}

export default App;
