import "./Suivie.css"


export default function Suivie(){
    return (
        <div className="suivie">
            <div className="suivie-element">
                <h3 className="titre-suivie">
                        SUIVEZ VOTRE COLIS
                </h3>
                <p className="mini-description-suivie">
                    Voyer en tout temps ou est rendu votre colis
                </p>

                <form className="form-suivie">
                    <input type="text" placeholder="Numéro de colis" className="form-numeroSuivie"/>
                    <input type="text" placeholder="Courriel" className="form-courriel"/>

                    <div className="button-suivie btn-style-spec">
                        Suivre mon colis
                    </div>
                </form>

            </div>
        </div>
    )
}