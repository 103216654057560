import './Images.css'

import logistic from './Logistics_and_rocket_2.png'
import logo from '../../assets/PNG-11.png'

export default function Images (){
    return (
       
            <div className='images'>
                
                <img alt='logo du site ' src={logo} className="logo-images"/>

            </div>
          
      
    )
}