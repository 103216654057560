import "./Services.css"

import image1 from "../../assets/delivery_box_phone_tracking2.svg"

import image2 from "../../assets/clipboard_box_delivery.svg"

import image3 from "../../assets/point_delivery_box.svg"

import image4 from "../../assets/parachute_delivery_box.svg"


export default function Services() {
    return (
        <div className="services">
            <h1 className="section-title">
                Un service de livraison hors de commun
            </h1>

            <section className=' section section1'>

                <div className="section-element">
                    
                    <h2 className="sub-title-section">
                        Livraison precise, Rien de moins
                    </h2>

                    <span className="acroche">
                        It’s not the time to guess
                    </span>

                    <p className="mini-text">
                        Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
                    </p>

                </div>

                <img className="img-section" src={image1} alt=" descriptif" />

            </section>

            <section className='section2 section'>
                <div className="section-element">
                    <h2 className="sub-title-section">
                        Livraison precise,Rien de moins
                    </h2>

                    <span className="acroche">
                        It’s not the time to guess
                    </span>

                    <p className="mini-text">
                        Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
                    </p>
                </div>

                <img className="img-section" src={image2} alt="descriptif" />

            </section>

            <section className='section3 section'>
                <div className="section-element">
                    <h2 className="sub-title-section">
                        Livraison precise , Rien de moins
                    </h2>
                    <span className="acroche">
                        It’s not the time to guess
                    </span>

                    <p className="mini-text">
                        Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
                    </p>
                </div>
                <img className="img-section" src={image3} alt="descriptif" />


            </section>

            <section className='section4 section'>
                <div className="section-element">
                    <h2 className="sub-title-section">
                        Livraison precise,Rien de moins
                    </h2>
                    <span className="acroche">
                        It’s not the time to guess
                    </span>

                    <p className="mini-text">
                        Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
                    </p>
                </div>

                <img className="img-section" src={image4} alt="descriptif" />


            </section>

            <section className="section5 ">
                <h1 className="section-title">
                    Vous ne nous croyer pas? Faites confiance a notre expertise.
                </h1>
                <p className="mini-text">
                    Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
                </p>

            </section>

        </div>
    )
}