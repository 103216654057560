import "./Footer.css"

import fleche from "../../assets/fleche-droite.png"

import logo from "../../assets/moncolis-logo.png"


export default function Footer() {
    return (
        <footer>

            <div className="footer-element">

                <div className="element-description">

                    <h3 className='footer-title'>
                        Mon Colis inc.
                    </h3>
                    <p className="footer-mini-text">
                        Confiez-nous votre bien-etre, on s'occuper de la livraison.
                    </p>


                </div>

                <div className="privacy-div">
                    <h3 ckassName="privacy">
                        Privacy policy
                    </h3>

                    <div style={{ width: 106, height: 0, border: '0.50px white solid' }}></div>
                </div>


                <div className="reseaux">
                    <h4 className="reseaux-title">
                        follow us
                    </h4>
                    <div className="links">
                        <img alt="icon lien" src={fleche} className="fleche" />
                        <a href="#"> Instagram</a>
                    </div>

                    <div className="links">
                        <img alt="icon lien" src={fleche} className="fleche" />
                        <a href="#"> Tik Tik</a>
                    </div>

                    <div className="links">
                        <img alt="icon lien" src={fleche} className="fleche" />
                        <a href="#"> Linkedin</a>
                    </div>
                    <div className="links">
                        <img alt="icon lien" src={fleche} className="fleche" />
                        <a href="#"> Twitter</a>
                    </div>
                </div>

                <img src={logo} alt="logo" className="logo-footer" />
            </div>


           
            <div>
                <p className="email">
                    moncolis.inc@gmail.com
                </p>



                <span className="copyright"> Copyright © 2024  Genesix Studio. All Rights Reserved.
                </span>
            </div>

        </footer>
    )
}